export const viewport = () => {
    const ua = navigator.userAgent;
    const metaElement = document.querySelector('meta[name="viewport"]');
    if (metaElement === null) {
        return;
    }
    if ((ua.indexOf('Android') > 0 && ua.indexOf('Mobile') == -1) || ua.indexOf('iPad') > 0 || ua.indexOf('Kindle') > 0 || ua.indexOf('Silk') > 0) {
        metaElement.setAttribute('content', 'width=1024');
    }
    else if (ua.indexOf('iPhone') > 0) {
        metaElement.setAttribute('content', 'width=device-width, user-scalable=no');
    }
    else if (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
        metaElement.setAttribute('content', 'width=device-width');
    }
    else {
        metaElement.setAttribute('content', 'width=1100');
    }
};
